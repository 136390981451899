import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import {fetchDashboardDataEpic,fetchAdminDataEpic,authenticateAdminEpic,fetchPrivacyEpic,fetchTermsAndConditionEpic,postPrivacy_Epic,postTerms_n_condition_Epic,addNotificationEpic,fetchUsersListEpic,fetchUserByIdEpic,enableUsersEpic,disableUsersEpic,postAdminDataEpic,updateAdminDataEpic,fetchCategoryEpic,postingCategoryEpic,enableCategoryEpic,disableCategoryEpic,updatingCategoryEpic ,fetchMenuListEpic,postMenuItemsEpic,fetchCategoryDropdownEpic,fetchAdminProfileEpic,updateMenuItemsEpic,activateMenuItemsEpic,deactivateMenuItemsEpic,fetchOrderListEpic,orderDetailsEpic,acceptOrderEpic, setOpeningClosingTimeEpic,openRestaurantEpic,closeRestaurantEpic, setOrderReadyEpic ,fetchNotificationEpic,makeSeenNotificationEpic } from "./myEpics";
import orkReducer from "./orkReducer";

export const rootReducer = combineReducers({
    mainState: orkReducer,
});
export const rootEpic = combineEpics(
    fetchDashboardDataEpic,
    fetchAdminDataEpic,
    fetchAdminProfileEpic,
    postAdminDataEpic,
    updateAdminDataEpic,

    authenticateAdminEpic,
    fetchUsersListEpic,
    enableUsersEpic,
    fetchUserByIdEpic,
    disableUsersEpic,

    postPrivacy_Epic,
    fetchPrivacyEpic,
    postTerms_n_condition_Epic,
    fetchTermsAndConditionEpic,
    addNotificationEpic,

    fetchCategoryEpic,
    fetchCategoryDropdownEpic,
    postingCategoryEpic,
    updatingCategoryEpic,
    enableCategoryEpic,
    disableCategoryEpic,

    fetchMenuListEpic,
    postMenuItemsEpic,
    updateMenuItemsEpic,
    activateMenuItemsEpic,
    deactivateMenuItemsEpic,

    fetchOrderListEpic,
    orderDetailsEpic,
    acceptOrderEpic,

    setOpeningClosingTimeEpic,
    openRestaurantEpic,
    closeRestaurantEpic,
    setOrderReadyEpic,

    fetchNotificationEpic,
    makeSeenNotificationEpic,

)
