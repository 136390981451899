import React, { useState, useEffect, useRef } from 'react'
import Layout from '../../Layout/Layout'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { connect, useDispatch } from 'react-redux'
import LoadingComponent from '../LoadingComponent'
import Footer from '../Z_Footer/Footer'
import { BsEyeFill, BsCheckCircle } from 'react-icons/bs'
import { BsX } from "react-icons/bs";
import { GiSkullCrossedBones } from 'react-icons/gi'
import { TiTick } from 'react-icons/ti'
import OrderDetails from './OrderDetails'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import moment from 'moment'

const mapStateToProps = store => {
    const { isLoadingOnLogin, orderList, isToogleSidebar, isEnabledUsers } = store.mainState;
    return { isLoadingOnLogin, orderList, isToogleSidebar, isEnabledUsers }
}

function OrderManagement({ isLoadingOnLogin, orderList, isToogleSidebar, isEnabledUsers }) {
    const [showSmModal, setShowSmModal] = useState(false);
    const [enableDisableItem, setEnableDisableItem] = useState({});
    const [reasonForEnableDisable, setReasonForEnableDisable] = useState("")
    const [orderStatus, setOrderStatus] = useState("ALL")
     const [myTime,setMyTime] = useState(0)
    const [forceToReload,setForceToReload] = useState(false)

    // for showing details of users
    const [openEye, setOpenEye] = useState(false);
    const [userDetailsId, setUserDetailsId] = useState(null);
    // Pagination

    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [currentPosts, setCurrentPosts] = useState([])

    let pageNumbers = []

    // get current posts
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch({
            type: "FETCH_ORDER_LIST_REQUEST",
            payload: { orderStatus, currentPage, postsPerPage }
        })
    }, [orderStatus, currentPage, postsPerPage])

    for (let i = 1; i <= orderList.totalPage; i++) {
        pageNumbers.push(i)
    }
    useEffect(() => {
        if (isEnabledUsers === "b") {
            setTimeout(() => {
                setShowSmModal(false);
                dispatch({
                    type: "FETCH_ORDER_LIST_REQUEST",
                    payload: { orderStatus, currentPage, postsPerPage }
                })
                setReasonForEnableDisable("")
            }, 1000);

        }
    }, [isEnabledUsers])
    const getLocalDateTime = (mydate) => {
        var stillUtc = moment.utc(mydate).toDate();
        var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
        return local
    }
    useEffect(()=> {
        setInterval(()=> {
        setMyTime(()=> myTime + 1)
        },120000)
        dispatch({
            type: "FETCH_ORDER_LIST_REQUEST",
            payload: { orderStatus, currentPage, postsPerPage }
        })
    },[myTime])

    const str = () => (
        orderList.data !== undefined && orderList.data.map((item) =>
            <tr key={item.paymentId}>
                <td>{item.customerOrderId}</td>
                <td>{item.scheduledTime !== "null" ? getLocalDateTime(item.scheduledTime): "Not Scheduled"}</td>
                <td>{item.customerInfo.fullName}</td>
                <td>Rs. {item.actualPaymentData.grandTotal}</td>
                <td>{item.deliveryAssignedTo !== null ? `${item.deliveryAssignedTo}` : "Not Assigned"}</td>
                <td><span style={{ backgroundColor: "purple", color: "white", borderRadius: "4px" }}>{item.orderStatus}</span></td>

                <td>
                    {item.orderStatus === "ORDER_CONFIRMED" && <Tippy content={<span>{item.orderStatus === "ORDER_CONFIRMED" && "Approve_Order"}</span>} placement="bottom">
                        <span>
                            <BsCheckCircle className="action--eye__admin" onClick={() => {
                                setShowSmModal(true);
                                setEnableDisableItem(item)
                            }} /> </span>
                    </Tippy>}
                    <Tippy content="View" placement="bottom">
                        <span>
                            <BsEyeFill className="action--edit__admin" onClick={() => {
                                setUserDetailsId(item.customerOrderId);
                                setOpenEye(true)
                            }} /> </span>
                    </Tippy></td>
            </tr>
        )
    )

    return (
        <Layout>
            <div>
                <div className="group_list">
                    <h4>Manage Order</h4>
                </div>
                <div className="user_groups">
                    <div className="user_groups--button_n_search">
                        <div className="user_groups--button_n_search--button">
                            <label htmlFor="pageSize" style={{ marginLeft: "10px", fontSize: '18px', fontFamily: "bold", color: "#3e445e", marginRight: "-10px" }}>Per Page</label>
                            <select name="pageSize" onClick={(e) => setPostsPerPage(e.target.value)}>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                            <label htmlFor="pageSize" style={{ marginLeft: "10px", fontSize: '18px', fontFamily: "bold", color: "#3e445e", marginRight: "-10px" }}>Order Status</label>
                            <select name="pageSize" onClick={(e) => setOrderStatus(e.target.value)}>
                                <option value="ALL">ALL</option>
                                <option value="ORDER_PLACED">ORDER_PLACED</option>
                                <option value="ORDER_CONFIRMED">ORDER_CONFIRMED</option>
                                <option value="PROCESSING">PROCESSING</option>
                                <option value="ORDER_READY">ORDER_READY</option>
                                <option value="DELIVERY_ON_THE_WAY">DELIVERY_ON_THE_WAY</option>
                                <option value="DELIVERY_REACHED_DESTINATION">DELIVERY_REACHED_DESTINATION</option>
                                <option value="DELIVERY_COMPLETED">DELIVERY_COMPLETED</option>
                                <option value="ACCEPTED">ACCEPTED</option>
                                <option value="COMPLETED">COMPLETED</option>
                            </select>
                        </div>
                        <div className="user_groups--button_n_search--search">
                        </div>
                    </div>
                    <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p>
                    <div className="table-container">
                        <table className="table_x">
                            <thead>
                                <tr>
                                    <th className="order--itemName">Order ID</th>

                                    <th className="order--sheduledTime">Sheduled Time</th>
                                    <th className="order--customerName">Customer Name</th>
                                    <th className="order--grandTotal">Grand Total</th>
                                    <th className="order--restaurantName">Assigned To</th>
                                    <th className="order--status">Order Status</th>

                                    <th className="order--actions">Actions</th>
                                </tr></thead>
                            <tbody>
                                {str()}
                            </tbody>
                        </table>
                        <div className={`${openEye ? "mymodal modal_activated" : "mymodal"}`}>
                            <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                                {userDetailsId !== null && <OrderDetails id={userDetailsId} />}
                            </div>
                            <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                                <div className="task--rectangle__circle">
                                    <div className='circle'> <span onClick={() =>
                                        setOpenEye(!openEye)
                                    }><BsX /></span><p>Close</p></div>
                                </div>
                            </div>
                        </div>
                        {/* for acceptin order  */}
                        <form>

                            <div className={`${showSmModal ? "delete--admin" : "delete--admin__hidden"}`} >
                                <div className="delete--admin--container" >
                                    <div className="delete--admin--container__heading">{enableDisableItem.orderStatus === "ORDER_CONFIRMED" ? <h1>Are you sure to Approve this Order ??</h1> : <h1>You have already accepted this order.</h1>}</div>
                                    <>
                                        <div className="delete--admin--container__body">

                                            <div>{isEnabledUsers === "a" && <div className="deleting--something">  <p>Processing...</p></div>}</div>
                                            <div> {isEnabledUsers === "b" && <div className="deleting--something"> <TiTick className="success--icon" /> <p>Successful</p></div>}</div>
                                            <div> {isEnabledUsers === "c" && <div className="deleting--something"> <GiSkullCrossedBones className="success--icon" style={{ fontSize: "30px" }} /> <p>Try Again</p>

                                            </div>}</div>
                                            <div className="reason--enableDisable">
                                                <label>Confirmation Text : </label><textarea type="text" required placeholder="Enter Reason" value={reasonForEnableDisable} onChange={(e) => setReasonForEnableDisable(e.target.value)} ></textarea>
                                            </div>
                                        </div>
                                        <div className="delete--admin--container__footer">
                                            <button onClick={() => setShowSmModal(!showSmModal)}>Cancel</button>
                                            {enableDisableItem.orderStatus === "ORDER_CONFIRMED" ? <button type="submit" onClick={(e) => {
                                                e.preventDefault()
                                                let enableDisableId = enableDisableItem.customerOrderId
                                                dispatch({
                                                    type: "ACCEPT_ORDER_REQUEST",
                                                    payload: {
                                                        id: enableDisableId,
                                                        data: {
                                                            "orderDecision": "APPROVE",
                                                            "confirmText": reasonForEnableDisable
                                                        }
                                                    }
                                                })
                                            }}>Ok</button> :
                                                <button>Ok</button>}
                                        </div>
                                    </>

                                </div>
                            </div>
                        </form>
                        <div className="pagination">
                            <p>Showing {indexOfFirstPost} to {indexOfLastPost > orderList.totalData ? orderList.totalData : indexOfLastPost} of {orderList.totalData} entries</p>
                            <nav aria-label="...">
                                <ul className="pagination">
                                    {currentPage === 1 ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft onClick={() => setCurrentPage(currentPage - 1)} /></a>
                                    </li>}
                                    {pageNumbers.map((x) =>
                                        <li key={x} className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(x)} >{x}</a></li>
                                    )}
                                    {currentPage == pageNumbers.length ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight onClick={() => setCurrentPage(currentPage + 1)} /></a>
                                    </li>}
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default connect(mapStateToProps, null)(OrderManagement)
