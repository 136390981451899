import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { AiOutlineHome, AiOutlineMenu } from 'react-icons/ai'
import { VscListOrdered } from 'react-icons/vsc'
import { IoIosAdd, IoIosRemove } from 'react-icons/io'
import { HiOutlineDocumentDuplicate, HiOutlinePlusCircle, HiTrendingUp } from 'react-icons/hi'
import { BsQuestionSquare, BsLock } from 'react-icons/bs'
import { toogleSidebar } from '../../Redux/actions'
import { BsX, BsChevronDoubleRight ,BsBell} from "react-icons/bs";
import { BiHelpCircle } from "react-icons/bi";
import AddAdmin from '../Admin Controller/AddAdmin'
import AddCategory from '../Category/AddCategory'
import AddMenu from '../Menu/AddMenu'
import ClosingOpeningTime from '../ClosingOpeningTime/ClosingOpeningTime'


const mapStateToProps = store => {
    const { isToogleSidebar, isAddCouponModal, isAddReataurantModal, isAddAdminRegisterModal, isAddCategoryModal, isAddMenuItemModal, isServiceRateModal, adminProfileData } = store.mainState;
    return { isToogleSidebar, isAddCouponModal, isAddReataurantModal, isAddAdminRegisterModal, isAddCategoryModal, isAddMenuItemModal, isServiceRateModal, adminProfileData }
}

function Sidebar({ isToogleSidebar, isAddCouponModal, isAddReataurantModal, isAddAdminRegisterModal, isAddCategoryModal, isAddMenuItemModal, isServiceRateModal, adminProfileData }) {
    // const [user, setUser] = useState(false);
    const [restaurantMng, setRetaurantMng] = useState(false);
    const [category, setCategory] = useState(false);
    const [menuMng, setMenuMng] = useState(false)
    const [orders, setOrders] = useState(false)
    const [coupon, setCoupon] = useState(false)
    // const [notification, setNotification] = useState(false);
    const [servicePakage, setServicePakage] = useState(false);
    const [serviceRage, setServiceRate] = useState(false);
    const [privilegePlan, setPrivilegePlan] = useState(false);
    const [review, setReview] = useState(false)
    const [games, setGames] = useState(false)
    const [payment, setPayment] = useState(false);
    const [loyality, setLoyality] = useState(false)
    const [privacy_n_terms, setPrivacy_n_terms] = useState(false);
    const [help, setHelp] = useState(false);
    const [rules_n_guidelines, setRules_n_guidelines] = useState(false)
    const [notification, setNotification] = useState(false)
    const [toggleTime, setToggleTime] = useState(false)
    const [showEditOnOffTimeModal, setShowEditOnOffTimeModal] = useState(false)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: "FETCH_ADMIN_USERS_PROFILE_REQUEST" })
    }, [])
    useEffect(() => {
        setToggleTime(!adminProfileData.isOpen)
    }, [adminProfileData])

    return (
        <> <section id={`${isToogleSidebar ? "sidebar_sm" : "sidebar"}`}>
            <div className={`${isToogleSidebar ? "_sidebar_sm" : "_sidebar"}`}>
                {!isToogleSidebar && <div className="_sidebar--main__openCloseTogler">
                    <div className="openCloseTogler">
                        <div className={`${toggleTime ? "openCloseTogler--container" : "openCloseTogler--containerOff"}`} >
                            <div className={`${toggleTime ? "openCloseTogler--container--para" : "openCloseTogler--containerOff--paraOff"}`}>
                                <div>OFF</div>
                                <div>ON</div>
                            </div>
                            {adminProfileData.isOpen ? <div className={`${toggleTime ? "openCloseTogler--container--button" : "openCloseTogler--containerOff--buttonOff"}`} onClick={() => {
                                setToggleTime(!toggleTime);
                                dispatch({
                                    type: "CLOSE_RESTAURANT_REQUEST",
                                    payload: {
                                        "isOpen": false
                                    }
                                })

                            }}>
                            </div> : <div className={`${toggleTime ? "openCloseTogler--container--button" : "openCloseTogler--containerOff--buttonOff"}`} onClick={() => {
                                setToggleTime(!toggleTime);
                                dispatch({
                                    type: "OPEN_RESTAURANT_REQUEST",
                                    payload: {
                                        "isOpen": true
                                    }
                                })

                            }}>
                            </div>}
                        </div>
                    </div>
                    <div className="showOnOffTime" onClick={() => {
                        setShowEditOnOffTimeModal(!showEditOnOffTimeModal)
                    }}>
                        <BsChevronDoubleRight className="myslidingIcon" />
                    </div>
                </div>}

                <div className="_sidebar--main__para">Main</div>
                <div className="_sidebar--main__dashboard">
                    <Link to="/dashboard" style={{ textDecoration: "none" }}><ul style={{color: "white"}}><span><AiOutlineHome /></span> Dashboard</ul></Link>
                </div>
                {/* <div className="_sidebar--main__dashboard" onClick={() => setPayment(!payment)}>
                    <Link to="#" style={{ textDecoration: "none" }}><ul><span><BsCreditCard /></span> Payments</ul></Link>
                </div> */}
                <div className="_sidebar--main__para">Components</div>
                {/* <div className={`${user ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setUser(!user)}>
                    <ul><span><FiUsers /></span> Customer {user ? <IoIosRemove className='add_icon' /> : <IoIosAdd className="add_icon" />} </ul>
                </div>
                <div className={`${user ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="/manage-customer" style={{ textDecoration: "none" }}>Manage</Link></li>
                </div> */}
                {/* <div className={`${restaurantMng ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setRetaurantMng(!restaurantMng)}>
                    <ul><span><IoRestaurantOutline /></span> Restaurant {restaurantMng ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${restaurantMng ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li> <Link to="/restaurant" style={{ textDecoration: "none" }}> Manage </Link> <span><HiOutlinePlusCircle className='add_task--icon' onClick={() => dispatch({ type: "ADD_RESTAURANT_MODAL" })} /></span></li>
                </div> */}
                <div className={`${category ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setCategory(!category)}>
                    <ul><span><HiOutlineDocumentDuplicate /></span> Category {category ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${category ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li > <Link to="category" style={{ textDecoration: "none",color:"white" }}> Manage</Link><span><HiOutlinePlusCircle className='add_task--icon' onClick={() => dispatch({ type: "ADD_CATEGORY_MODAL" })} /></span></li>
                </div>
                <div className={`${menuMng ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setMenuMng(!menuMng)}>
                    <ul><span><AiOutlineMenu /></span> Menu {menuMng ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${menuMng ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li> <Link to="menu" style={{ textDecoration: "none",color:"white" }}> Manage Menu</Link><span><HiOutlinePlusCircle className='add_task--icon' onClick={() => dispatch({ type: "ADD_MENU_ITEM_MODAL" })} /></span></li>
                </div>

                <div className={`${orders ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setOrders(!orders)}>
                    <ul><span><VscListOrdered /></span> Orders {orders ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${orders ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="order-manage" style={{ textDecoration: "none",color:"white" }}>Manage Orders</Link></li>
                </div>
                <div className={`${notification ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setNotification(!notification)}>
                    <ul><span><BsBell /></span> Notifications {notification ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${notification ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="notification" style={{ textDecoration: "none",color:"white" }}>Notification</Link></li>
                </div>
                <div className={`${servicePakage ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setServicePakage(!servicePakage)}>
                    <ul><span><BiHelpCircle /></span> Support {servicePakage ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${servicePakage ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="support" style={{ textDecoration: "none",color:"white" }}>Support</Link></li>
                </div>

                {/* <div className={`${privilegePlan ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setPrivilegePlan(!privilegePlan)}>
                    <ul><span><BsCardChecklist /></span>Privilege Plan {privilegePlan ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${privilegePlan ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="#" style={{ textDecoration: "none" }}>Manage Permission</Link></li>
                </div> */}
                {/* <div className={`${review ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setReview(!review)}>
                    <ul><span><MdRateReview /></span> Reviews {review ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${review ? "category_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="#" style={{ textDecoration: "none" }}>Role & Permissions</Link></li>
                    <li> <Link to="#" style={{ textDecoration: "none" }}> Manage R&P</Link> </li>
                </div>
                <div className={`${games ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setGames(!games)}>
                    <ul><span><IoGameControllerOutline /></span> Games {games ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${games ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="#" style={{ textDecoration: "none" }}>Manage Questions</Link><span><HiOutlinePlusCircle className='add_task--icon' onClick={() => dispatch({
                        type: "OPEN_SECURITY_QTN_MODAL"
                    })} /></span></li>
                </div> */}

                {/* <div className={`${loyality ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setLoyality(!loyality)}>
                    <ul><span><BsCommand /></span> Loyality & Discount {loyality ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${loyality ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="#" style={{ textDecoration: "none" }}>Manage Loyality</Link><span><HiOutlinePlusCircle className='add_task--icon' onClick={() => dispatch({ type: "OPEN_LOYALITY_SCHEME_MODAL" })} /></span></li>
                </div> */}
                {/* <div className={`${notification ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setNotification(!notification)}>
                    <ul><span><IoMdNotificationsOutline /></span> Notification {notification ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${notification ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="notification" style={{ textDecoration: "none" }}>Add Notification</Link></li>
                </div> */}
                {/* <div className={`${help ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setHelp(!help)}>
                    <ul><span><BiHelpCircle /></span> Help {help ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>

                </div>
                <div className={`${help ? "category_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="#" style={{ textDecoration: "none" }}>FAQ?</Link><span><HiOutlinePlusCircle className='add_task--icon' onClick={() => dispatch({
                        type: "OPEN_FAQ_MODAL"
                    })} /></span></li>
                    <li><Link to="#" style={{ textDecoration: "none" }}>Supports</Link></li>
                </div> */}
                <div className={`${privacy_n_terms ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setPrivacy_n_terms(!privacy_n_terms)}>
                    <ul><span><BsQuestionSquare /></span> Terms And Conditions {privacy_n_terms ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${privacy_n_terms ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="terms-condition" style={{ textDecoration: "none",color:"white" }}>Manage</Link></li>
                </div>
                {/* <div className={`${rules_n_guidelines ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setRules_n_guidelines(!rules_n_guidelines)}>
                    <ul><span><BsLayoutTextSidebar /></span> Rule/Guidance {rules_n_guidelines ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${rules_n_guidelines ? "category_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="#" style={{ textDecoration: "none" }}>Rules</Link></li>
                    <li><Link to="#" style={{ textDecoration: "none" }}>Guidelines</Link></li>
                </div> */}
                <div className="_sidebar--main__dashboard-last">
                    <Link to="privacy-policy" style={{ textDecoration: "none" }}><ul style={{ color: "white" }}><span><BsLock /></span> Privacy And Policy <IoIosAdd className="add_icon" /></ul></Link>
                </div>

            </div>

        </section>
            {/* add admin register modal */}
            <div className={`${isAddAdminRegisterModal ? "mymodal modal_activated" : "mymodal"}`}>
                <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                    <AddAdmin />
                </div>
                <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                    <div className="task--rectangle__circle">
                        <div className='circle'> <span onClick={() => {
                            let myKeyword = "";
                            let sortingParam = "NOT_VERIFIED";
                            let currentPage = 1;
                            let postsPerPage = 10;
                            dispatch({ type: "ADD_ADMIN_REGISTER_MODAL" });
                            dispatch({
                                type: "FETCH_ADMIN_USERS_REQUEST",
                                payload: {
                                    currentPage, postsPerPage, myKeyword, sortingParam
                                }
                            })
                        }}><BsX /></span><p>Add</p></div>
                    </div>
                </div>
            </div>
            {/* add Category modal */}
            <div className={`${isAddCategoryModal ? "mymodal modal_activated" : "mymodal"}`}>
                <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                    <AddCategory />
                </div>
                <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                    <div className="task--rectangle__circle">
                        <div className='circle'> <span onClick={() => {
                            let currentPage = 1;
                            let postsPerPage = 10;
                            dispatch({ type: "ADD_CATEGORY_MODAL" });
                            dispatch({
                                type: "FETCH_CATEGORY_LIST_REQUEST",
                                payload: { postsPerPage, currentPage }
                            })
                        }}><BsX /></span><p>Add</p></div>
                    </div>
                </div>
            </div>
            {/* add Menu item modal */}
            <div className={`${isAddMenuItemModal ? "mymodal modal_activated" : "mymodal"}`}>
                <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                    <AddMenu />
                </div>
                <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                    <div className="task--rectangle__circle">
                        <div className='circle'> <span onClick={() => {
                            dispatch({ type: "ADD_MENU_ITEM_MODAL" });
                        }}><BsX /></span><p>Add</p></div>
                    </div>
                </div>
            </div>
            {/* show restaurnat opening and closing time */}
            <div className={`${showEditOnOffTimeModal ? "mymodal modal_activated" : "mymodal"}`}>
                <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                    <ClosingOpeningTime />
                </div>
                <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                    <div className="task--rectangle__circle">
                        <div className='circle'> <span onClick={() => {
                            setShowEditOnOffTimeModal(!showEditOnOffTimeModal)
                        }}><BsX /></span><p>Edit</p></div>
                    </div>
                </div>
            </div>

        </>

    )
}

const mapDispatchToProps = dispatch => {
    return {
        toogleSidebar: () => dispatch(toogleSidebar())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
