import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { handleAdminCre_Input, loginRequest } from "../../../Redux/actions";
import OrkmateLogo from "../../../Images/logo-dark.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const mapStateToProps = (store) => {
  const { adminCredencial, isLoadingOnLogin, isSuccessOnLogin } =
    store.mainState;
  return { adminCredencial, isLoadingOnLogin, isSuccessOnLogin };
};

const LogIn = ({
  handleAdminCre_Input,
  loginRequest,
  adminCredencial,
  isLoadingOnLogin,
  isSuccessOnLogin,
}) => {
  const [eyeState, setEyeState] = useState(false);

  const { userName, password } = adminCredencial;
  const handleInput = (name) => (event) => {
    handleAdminCre_Input({ ...adminCredencial, [name]: event.target.value });
  };
  if (isSuccessOnLogin === true) {
    window.location.replace("/dashboard");
    toast.success("Login Successfull !!!");
  }

  return (
    <div className="admin-login">
      <ToastContainer />
      <form>
        <div className="admin-login--heading">
          <p>Restaurant Login</p>
          <div className="admin-login--heading__imgContainer">
            <img src={OrkmateLogo} alt="Gurug Logo" />
          </div>
        </div>
        <div className="login_loading--text">
          {isLoadingOnLogin && "Loading..."}
          {isSuccessOnLogin && "Succed!!"}
        </div>
        <div className="admin-login--body">
          <div className="email--field">
            <p>Email </p>
            <input
              type="email"
              placeholder="Enter Email"
              value={userName}
              required="true"
              onChange={handleInput("userName")}
            />
          </div>
          <div className="password--field">
            <p>Password </p>
            <input
              type={`${eyeState ? "text" : "password"}`}
              placeholder="Enter Password"
              required="true"
              value={password}
              onChange={handleInput("password")}
            />
            <span className="eye-icon">
              {eyeState ? (
                <AiOutlineEyeInvisible onClick={() => setEyeState(!eyeState)} />
              ) : (
                <AiOutlineEye onClick={() => setEyeState(!eyeState)} />
              )}
            </span>
          </div>
        </div>
        <div className="admin-login--footer">
          <button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              loginRequest(adminCredencial);
            }}
          >
            Log In
          </button>
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleAdminCre_Input: (values) => dispatch(handleAdminCre_Input(values)),
    loginRequest: (Credencial) => dispatch(loginRequest(Credencial)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
