import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import { BsChevronDoubleDown } from 'react-icons/bs'
import TimePicker from 'react-time-picker';
import dayjs from 'dayjs'
import moment from 'moment'

const mapStateToProps = store => {
    const { buttonText, adminProfileData, isEnabledUsers } = store.mainState
    return { buttonText, adminProfileData, isEnabledUsers }
}

function ClosingOpeningTime({ buttonText, adminProfileData, isEnabledUsers }) {
    const [showMore, setShowMore] = useState(false);
    const [myOpeningTime, setMyOpeningTime] = useState("")
    const [myClosingTime, setMyClosingTime] = useState("")
    const [a, setA] = useState("")
    const [ab, setAb] = useState("")
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({ type: "FETCH_ADMIN_USERS_PROFILE_REQUEST" })
    }, [])
    useEffect(() => {
        let x = getLocalDateTime(`2021-01-03 ${adminProfileData.openingTime}`)
        let myTimeA = dayjs(x).format("DD MMM YYYY h:mm A").split(" ")
        setA(myTimeA)
        let y = getLocalDateTime(`2020-01-01 ${adminProfileData.closingTime}`)
        let myTimeB = dayjs(y).format("DD MMM YYYY h:mm A").split(" ")
        setAb(myTimeB)

    }, [adminProfileData])

    const handleSubmit = (e) => {
        e.preventDefault();
        let openingTimeX = `2021-01-15 ${myOpeningTime}`
        let openingTimey = `2021-01-15 ${myClosingTime}`
        let m = new Date(openingTimeX).toUTCString().split(" ")
        let n = new Date(openingTimey).toUTCString().split(" ")
        dispatch({
            type: "SET_CLOSING_OPENING_TIME_REQUEST",
            payload: {
                "isOpen": true,
                "openingTime": m[4],
                "closingTime": n[4]
            }
        })
    }

    useEffect(() => {
        if (isEnabledUsers === "b") {
            dispatch({ type: "FETCH_ADMIN_USERS_PROFILE_REQUEST" })
            setShowMore(false)
        }

    }, [isEnabledUsers])
    useEffect(() => {

        let x = getLocalDateTime(`2021-01-03 ${adminProfileData.openingTime}`).split(" ")[1]

        let y = getLocalDateTime(`2020-01-01 ${adminProfileData.closingTime}`).split(" ")[1]


        setMyOpeningTime(x)
        setMyClosingTime(y)
    }, [adminProfileData])


    const getLocalDateTime = (mydate) => {
        var stillUtc = moment.utc(mydate).toDate();
        var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
        return local
    }

    return (
        <div>
            <div className="Modal--heading">
                <p style={{ color: "black" }}>Set Opening / Closing Time</p>
            </div>
            <form>
                <div className="Modal--form__restaurantOnOff">
                    <div className="showOpeningClosingTime">
                        {/* <p>Opening Time : <span>{a[3]} {a[4]}</span></p>
                        <p>Closing Time : <span>{ab[3]} {ab[4]}</span></p> */}
                        <p>Opening Time : <span>{a[3]} {a[4]}</span></p>
                        <p>Closing Time : <span>{ab[3]} {ab[4]}</span></p>

                    </div>
                    <div className={`${showMore ? "uparrow" : "downArrow"}`} onClick={() => setShowMore(!showMore)}>
                        <BsChevronDoubleDown />
                    </div>
                    {showMore && <div className="setOpeningClosingTime">
                        <p>Opening Time : <TimePicker
                            onChange={setMyOpeningTime}
                            value={myOpeningTime}
                        /></p>
                        <p>Closing Time : <TimePicker
                            onChange={setMyClosingTime}
                            value={myClosingTime}
                        /></p>
                        <div className="modal--container__group--buttons">
                            <button type="submit" className="btn btn-primary" onClick={handleSubmit} style={{ marginBottom: "50px" }}>
                                {buttonText}
                            </button>
                        </div>
                    </div>}

                </div>
            </form>
        </div>
    )
}

export default connect(mapStateToProps, null)(ClosingOpeningTime)
